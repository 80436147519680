<template>
  <div class="rule-page">
    <div class="ruleIcon">
			<img src="./img/ruleIcon.png" />
		</div>
    <div class="ruleContent">
			<ul>
				<li>1、针对有鱼定制产品，每月每样奖品限兑换1次，奖品每月1号更新库存，当月消耗完则在下月1号补充，若当月有剩余则将库存转移至下月。</li>
				<li>2、商品一经兑换，一律不退还积分，请用户兑换前仔细参照使用规则等信息；</li>
				<li>3、对于每月每位用户限兑1次的有鱼定制商品，若发现多个用户账号使用相同手机号或收货地址兑换同一商品，则自动取消订单，被扣除的金豆不退还；</li>
				<li>4、针对超值优惠券专区的商品，每款商品均能兑换1次，如有10款不同商品，则可各领1个券码，券码有效期至2018年10月31日。</li>
				<li>5、兑换成功后，复制优惠券码，点击进入产品页，点击立即购买-在订单页面输入券码兑换，价格变为兑换价后提交订单，等待收货。</li>
				<li>6.该券为指定商品，其它产品不可用，每日4点前下单，都可当天发货。 每位券码限领1条，券码使用后即废。</li>
				<li>7、通过非法途径获得金豆后进行的兑换，或不按本站使用规则进行兑换，本站有权不提供服务；</li>
				<li>8、凡以不正当手段（包括但不限于作弊、扰乱系统、实施网络攻击等）进行兑换，平台有权终止您的兑换权利。</li>
				<li>9、兑换专区商品最终解释权归有鱼记账所有，如有疑问请联系平台处理。</li>
			</ul>
      <p class="weixin">微信号：youyujz002（工作日：9：00-18：00)</p>
		</div>
  </div>
</template>
<script>
export default {
  name: 'GoldRule',
  mounted() {
    document.title = '活动规则';
  },
};
</script>
<style scoped lang="scss">
  .rule-page {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 0.1px;
    overflow-y: auto;
    .ruleIcon {
      width: 18.9%;
      margin: 0.253333rem 0 0.266666rem 0;
      img {
        width: 100%;
      }
    }
    .ruleContent {
      width: 91.9%;
      margin: 0 auto;
      padding-bottom: 20px;
      .weixin {
        font-size: .37rem;
        margin-top: .5rem;
      }
    }
    .ruleContent > ul > li {
      font-size: 0.373333rem;
      line-height: 1.86;
      letter-spacing: -0.2px;
      color: #333333;
    }
  }
</style>
